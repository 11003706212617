#top{
	
	.header-logo{
		margin-right: auto !important;
	}
	.header .sub-menu li{
		padding-right: 0;
		a:before{
			display: none;
		}
	} 
	.header-menu{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		a{
			&:before{
				transform: translateY(100%) translateX(-50%);
			}
			.menu-icon{
				height: 18px;
			}
		}
	}
	.secondary-menu{
		display: flex;
		width: auto;
		.menu-item{
			position: relative;
			a{
				padding-top: 8px;
			}
			&:not(:last-child){
				margin-right: 10px;
			}
		}
	}
	.pill-left{
		&:before{
			left: -350px !important;	
		}
	}
	.pill-right{
		&:before{
			right: -350px !important;	
		}
	}
	.testimonial-slider{
		.flickity-button{
			height: 34px;
			width: 34px;
			
		}
	}
	.ep-custom-menu-element ul li ul{
		display: block;
	}
	.ep-item-grid-content-wrapper{
		width: 100%;
	}
	.ep-accordion-wrapper .accordion-content-inner{
		summary{
			&:before{
				display: none !important;
			}
		}
	}
	.impact-list{
		.ep-item-grid-item-inner{
			padding-top: 200px;
			box-shadow: -20px -20px 20px rgba(0,0,0,0.16);
		}
		.ep-item-grid-item-title{
			opacity: 1 !important;
		}
		.ep-item-grid-item-content{
			opacity: 1 !important;
			height: 200px;
			max-height: 200px !important;
		}
		.ep-item-grid-item-media {
			height: 110px !important;
			width: 110px !important;
			background-color: #8AD625 !important;
			top: 50px !important;
			left: 100px !important;
			.ep-item-grid-icon{
				height: 100% !important;
				width: 100% !important;
				&:before{
					opacity: 1 !important;
				}
			}
		}
	}
}